<template>
    <div class="featured-vehicles container-fluid" v-if="featuredVehicles">
        <div>
            <h1>{{ featuredVehicles.categoryName }}</h1>
            <div class="vehicle-stat-switch custom-control custom-switch">
                <input v-model="showDetail" type="checkbox" :id="switchId" class="custom-control-input" />
                <label class="custom-control-label" :for="switchId">show vehicle stats</label>
            </div>
            <div class="row pb-5">
                <div class="col-6 mb-3 col-lg" v-for="(vehicle, $idx) in featuredVehicles.vehicles" :key="$idx">
                    <VehicleCard :vehicle="vehicle" :showDetail="showDetail" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const VehicleCard = () => import("Components/VehicleCard");
export default {
    props: {
        vehicles: {
            type: Array,
        },
        sectionIndex: {
            type: Number,
        }
    },
    data() {
        return {
            showDetail: false,
            switchId: ""
        }
    },
    mounted() {
        this.switchId = `ShowStatsSwitch${this._uid}`;
    },
    computed: {
        featuredVehicles() {
            return this.vehicles || this.$store.state.featuredVehicles?.[this.sectionIndex];
        }
    },
    components: {
        VehicleCard
    }
}
</script>
<style lang="scss" scoped>
.featured-vehicles {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;

    h1 {
        color: black;
        font-size: 1.5rem;
        font-weight: 900;
    }

    .vehicle-stat-switch {
        font-size: 1rem;
        color: $dark-grey;

        .custom-control-input {
            width: 100px;
        }
    }
}
</style>